<template>
  <div class="main">
    <div class="banner">
      <img class="bg" src="../assets/001.jpg" v-if="screenWidth > 768" />
      <img class="bg" src="../assets/phone/001.jpg" v-else />
      <div class="icon_box">
        <div class="kuai img1">
          <img
            src="../assets/scoll.png"
            @click="goLink2"
            v-if="screenWidth > 768"
          />
          <img src="../assets/phone/scoll.png" @click="goLink2" v-else />
        </div>
        <div class="kuai img2">
          <img
            src="../assets/scoll.png"
            @click="goLink1"
            v-if="screenWidth > 768"
          />
          <img src="../assets/phone/scoll.png" @click="goLink1" v-else />
        </div>
        <div class="kuai img3">
          <img
            src="../assets/scoll.png"
            @click="goLink3"
            v-if="screenWidth > 768"
          />
          <img src="../assets/phone/scoll.png" @click="goLink3" v-else />
        </div>
        <div class="kuai img4">
          <img
            src="../assets/scoll.png"
            @click="goLink4"
            v-if="screenWidth > 768"
          />
          <img src="../assets/phone/scoll.png" @click="goLink4" v-else />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      icon1: require("../assets/01.png"),
      icon2: require("../assets/02.png"),
      icon3: require("../assets/03.png"),
      icon4: require("../assets/04.png"),
      icon5: require("../assets/05.png"),
      screenWidth: window.outerWidth,
    };
  },
  methods: {
    goLink1() {
      if (this.screenWidth > 768) {
        window.open("https://www.jlwdigit.com");
      } else {
        window.location.href = "https://www.jlwdigit.com";
      }
    },
    goLink2() {
      if (this.screenWidth > 768) {
        window.open("https://www.jlwyx.com");
      } else {
        window.location.href = "https://www.jlwyx.com";
      }
    },
    goLink3() {
      if (this.screenWidth > 768) {
        window.open("https://www.jlwsunny.com");
      } else {
        window.location.href = "https://www.jlwsunny.com";
      }
    },
    goLink4() {
      if (this.screenWidth > 768) {
        window.open("https://www.jlwpay.com");
      } else {
        window.location.href = "https://www.jlwpay.com";
      }
    },
    goLink5() {
      //window.open("https://www.jlwpay.com");
    },
    goOver(num) {
      if (num == 1) {
        this.icon1 = require("../assets/11.png");
      } else if (num == 2) {
        this.icon2 = require("../assets/22.png");
      } else if (num == 3) {
        this.icon3 = require("../assets/33.png");
      } else if (num == 4) {
        this.icon4 = require("../assets/44.png");
      }
    },
    goOut(num) {
      if (num == 1) {
        this.icon1 = require("../assets/01.png");
      } else if (num == 2) {
        this.icon2 = require("../assets/01.png");
      } else if (num == 3) {
        this.icon3 = require("../assets/03.png");
      } else if (num == 4) {
        this.icon4 = require("../assets/04.png");
      }
    },
    updateScreenWidth() {
      this.screenWidth = window.outerWidth;
      localStorage.setItem("screenWidth", this.screenWidth);
    },
  },
  mounted() {
    window.addEventListener("resize", this.updateScreenWidth);
  },
};
</script>
<style scoped>
.main {
  width: 100%;
}
.banner {
  width: 100%;
  height: 1000px;
}
.banner .bg {
  width: 100%;
  position: relative;
}
.area {
  position: absolute;
  width: 55%;
  top: 60%;
  left: 20%;
}
.area img {
  width: 20%;
}
.icon_box {
  position: absolute;
  top: 115%;
  left: 20%;
  width: 60%;
  height: auto;
  padding-left: 15px;
}
.kuai {
  position: relative;
  float: left;
  width: 48%;
  height: 37vh;
  margin-bottom: 30px;
  margin-right: 10px;
}
.kuai img {
  cursor: pointer;
  position: absolute;
  bottom: 4vh;
  left: 50px;
}

.img1 {
  background: url("../assets/bk1.png") no-repeat;
  background-size: cover;
}
.img2 {
  background: url("../assets/bk2.png") no-repeat;
  background-size: cover;
}
.img3 {
  background: url("../assets/bk3.png") no-repeat;
  background-size: cover;
}
.img4 {
  background: url("../assets/bk4.png") no-repeat;
  background-size: cover;
}
@media screen and (min-width: 768px) {
  .kuai img:hover {
    scale: 1.5;
  }
}
@media screen and (max-width: 768px) {
  .icon_box {
    left: 1%;
    width: 95%;
    padding-left: 10px;
    /* top: 324px; */
    top: 44%;
  }
  .kuai {
    position: relative;
    float: left;
    width: 47%;
    height: 15vh;
    margin-bottom: 10px;
    margin-right: 10px;
  }
  .kuai img {
    width: 70px;
    cursor: pointer;
    position: absolute;
    bottom: 1vh;
    left: 10px;
  }
  .img1 {
    background: url("../assets/phone/bk1.png") no-repeat;
    background-size: cover;
  }
  .img2 {
    background: url("../assets/phone/bk2.png") no-repeat;
    background-size: cover;
  }
  .img3 {
    background: url("../assets/phone/bk3.png") no-repeat;
    background-size: cover;
  }
  .img4 {
    background: url("../assets/phone/bk4.png") no-repeat;
    background-size: cover;
  }
}
</style>